import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import MKBox from "components/MDBox";
import MKTypography from "components/MDTypography";
import MKButton from "components/MDButton";

import bgImage from "assets/images/back9.png";

function SupportContent() {
    return (
        <>
            <MKBox
                minHeight="50vh"
                width="100%"
                sx={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid container item xs={12} lg={7} justifyContent="center" mx="auto" mt={9}>
                        <MKTypography
                            variant="h2"
                            color="white"
                            textAlign="center"
                        >
                            We're Here To Help
                        </MKTypography>
                        <MKTypography
                            variant="h5"
                            color="white"
                            textAlign="center"
                            mt={2}
                        >
                            Your financial success drives everything we do. Our dedicated support team is ready to
                            assist you.
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>

            <MKBox component="section" py={12}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} mx="auto">
                            <MKTypography variant="h3" mb={3} color="info">
                                Support Options
                            </MKTypography>

                            <MKTypography variant="h5" mb={2}>
                                In-App Support (Recommended)
                            </MKTypography>
                            <MKTypography variant="body1" mb={4} color="text">
                                Get instant support through our in-app system. Use the support icon to:
                                <ul>
                                    <li>Report bugs or issues</li>
                                    <li>Submit feature requests</li>
                                </ul>
                            </MKTypography>

                            <MKTypography variant="h5" mb={2}>
                                Email Support
                            </MKTypography>
                            <MKTypography variant="body1" mb={4} color="text">
                                If you cannot access the in-app support system, you can email our support team at:
                                <br/>
                                <MKButton
                                    variant="text"
                                    color="info"
                                    href="mailto:hello@growyourbucks.com"
                                >
                                    hello@growyourbucks.com
                                </MKButton>
                            </MKTypography>

                            <Divider sx={{my: {xs: 2, sm: 8}}}/>

                            <MKTypography variant="h3" mb={3} color="info">
                                Frequently Asked Questions
                            </MKTypography>

                            <MKTypography variant="h5" mb={1}>
                                Is the app really free?
                            </MKTypography>
                            <MKTypography variant="body1" mb={3} color="text">
                                Yes! Our app is completely free to use. We believe in making powerful financial tools
                                accessible to everyone.
                            </MKTypography>

                            <MKTypography variant="h5" mb={1}>
                                Is my data secure?
                            </MKTypography>
                            <MKTypography variant="body1" mb={3} color="text">
                                Yes, we implement industry-leading security measures to protect your data, including
                                advanced encryption and secure servers.
                            </MKTypography>

                            <MKTypography variant="h5" mb={1}>
                                Are your financial calculations reliable?
                            </MKTypography>
                            <MKTypography variant="body1" mb={3} color="text">
                                Yes, all our calculations use industry best practices and accepted formulas to ensure
                                accurate financial planning and analysis.
                            </MKTypography>

                            <MKTypography variant="h5" mb={1}>
                                Can I delete my account and data?
                            </MKTypography>
                            <MKTypography variant="body1" mb={3} color="text">
                                Yes, you can easily request account and data deletion through your profile settings in
                                the app at any time.
                            </MKTypography>

                            <MKTypography variant="h5" mb={1}>
                                Where can I access the app?
                            </MKTypography>
                            <MKTypography variant="body1" mb={3} color="text">
                                Yes, our app is available everywhere - access it through web browsers, iOS App Store,
                                and Google Play Store for Android devices.
                            </MKTypography>

                            <Divider sx={{my: {xs: 2, sm: 8}}}/>

                            <MKTypography variant="h5" mb={2}>
                                Company Information
                            </MKTypography>
                            <MKTypography variant="body1" color="text">
                                Domivi LLC
                                <br/>
                                1111B S Governors Ave
                                <br/>
                                STE 23686
                                <br/>
                                Dover, DE 19904
                                <br/>
                                United States
                            </MKTypography>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
        </>
    );
}

export default SupportContent;
