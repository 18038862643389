import Login, {loginAction} from 'pages/public/Login.js';
import Verifyphone, {verifyPhoneAction} from "../pages/public/Verifyphone";
import Editphone, {editPhoneAction} from "../pages/public/Editphone";
import {RequestPasswordReset, requestPasswordResetAction} from "../pages/public/RequestPasswordReset";
import {ResetRequested, resetRequestedLoader} from "../pages/public/ResetRequested";
import {PasswordReset, passwordResetAction, passwordResetLoader} from "../pages/public/PasswordReset";
import {VerifyEmail, verifyEmailLoader} from "../pages/public/VerifyEmail";
import Home from "../pages/public/Home";
import HomeContent from "../pages/public/components/HomeContent";
import {signupAction} from "../pages/public/components/Signup";
import {TermsAndConditionsUser} from "../pages/main/TermsAndConditionsUser"
import {PrivacyPolicyUser} from "../pages/main/PrivacyPolicyUser";
import Advisors from "../pages/public/Advisors";
import SupportContent from "../pages/public/SupportPage";

const publicRoutes = [
    {
        path: '/',
        element: <Home/>,
        children: [
            {
                index: true,
                element: <HomeContent/>,
                action: signupAction,
            },
            {
                path: '/login',
                element: <Login/>,
                action: loginAction,
            },
            {
                path: '/requestpasswordreset',
                element: <RequestPasswordReset/>,
                action: requestPasswordResetAction,
            },
            {
                path: '/resetrequested',
                element: <ResetRequested/>,
                loader: resetRequestedLoader
            },
            {
                path: '/passwordreset',
                element: <PasswordReset/>,
                loader: passwordResetLoader,
                action: passwordResetAction
            },
            {
                path: '/verifyemail',
                element: <VerifyEmail/>,
                loader: verifyEmailLoader
            },
            {
                path: '/termsAndConditions',
                element: <TermsAndConditionsUser/>
            },
            {
                path: '/privacyPolicy',
                element: <PrivacyPolicyUser/>
            },
            {
                path: '/support',
                element: <SupportContent/>,
            },
        ]
    },
    {
        path: '/advisors',
        element: <Advisors/>,
    },

    {
        path: '/verifyphone',
        element: <Verifyphone/>,
        action: verifyPhoneAction
    },
    {
        path: '/editphone',
        element: <Editphone/>,
        action: editPhoneAction
    },

];

export default publicRoutes;